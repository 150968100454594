<template>
  <v-container>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <v-card :loading="api.isLoading" class="mx-auto performance">
      <v-card-text>
        <v-simple-table>
          <v-list>
            <div>
              <div class="pa-3 table-header-cell text-left">
                <span class="chapter-name">{{ $t("model.name.chapter") }}</span>
              </div>
              <div class="pa-3 table-header-cell">
                {{ $t("model.name.submissions") }}
              </div>
              <div class="pa-3 table-header-cell">
                {{ $t("model.prop.stars") }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >0 {{ $t("model.prop.stars") }}:
                    {{ $t("view.PagePerformance.star_calculation_message") }}
                    {{ $t("model.prop.accuracy") }} <br />
                    1 {{ $t("model.prop.stars") }}: >60%
                    {{ $t("model.prop.accuracy") }} <br />
                    2 {{ $t("model.prop.stars") }}: > 80%
                    {{ $t("model.prop.accuracy") }} <br />
                    3 {{ $t("model.prop.stars") }}: >= 100%
                    {{ $t("model.prop.accuracy") }}</span
                  >
                </v-tooltip>
              </div>
              <!-- 
              <div class="pa-3 table-header-cell">
                {{ $t("model.prop.score") }}
              </div> -->
              <div class="pa-3 table-header-cell">
                {{ $t("model.prop.corrects") }}
              </div>
              <div class="pa-3 table-header-cell">
                {{ $t("model.prop.accuracy") }}
              </div>
              <!-- <div class="pa-3 table-header-cell">
                {{ $t("model.prop.time") }}
              </div> -->
              <div class="pa-3 table-header-cell"></div>
            </div>
            <v-list-group
              v-for="(item, indexc) in chapterWithRecord"
              :v-model="item.key == chapterKeyShow ? true : false"
              :key="indexc"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content @click="showChapter(item.key)">
                  <div class="resp-table-row">
                    <div class="table-body-cell text-left">
                      <span class="chapter-name">
                        {{ item.sort }}.
                        {{ JSON.parse(item.translatableName)[$_getLocale()] }}
                      </span>
                    </div>
                    <div class="pa-3 table-body-cell" v-if="item.Record">
                      <span v-if="item.Record.attempt">
                        {{ item.Record.attempt }}</span
                      >
                      <span v-else>-</span>
                    </div>
                    <div class="pa-3 table-body-cell" v-else>0</div>
                    <div class="pa-3 table-body-cell" v-if="item.Record">
                      {{ item.Record.star }}/{{
                        item.ChapterQuestion.length * 3
                      }}
                      ★
                    </div>
                    <div class="pa-3 table-body-cell" v-else>
                      0/{{ item.ChapterQuestion.length * 3 }} ★
                    </div>
                    <!-- 
                    <div class="pa-3 table-body-cell" v-else>0 ★</div>
                    <div class="pa-3 table-body-cell" v-if="item.Record">
                      {{ item.Record.totalScore }}
                    </div>
                    <div class="pa-3 table-body-cell" v-else>0</div> -->
                    <!-- <div class="pa-3 table-body-cell">-</div> -->
                    <div v-if="item.Record" class="pa-3 table-body-cell">
                      {{ item.Record.totalCorrect }}/{{
                        item.Record.totalQuestion
                      }}
                    </div>
                    <div class="pa-3 table-body-cell" v-else>0</div>
                    <div v-if="item.Record" class="pa-3 table-body-cell">
                      {{
                        Math.round(
                          ((item.Record.totalCorrect /
                            item.Record.totalQuestion) *
                            100 +
                            Number.EPSILON) *
                            100
                        ) / 100
                      }}%
                    </div>
                    <div class="pa-3 table-body-cell" v-else>0%</div>
                    <div class="pa-3 table-body-cell">
                      <span
                        ><v-icon v-if="chapterKeyShow == item.key"
                          >mdi-menu-up</v-icon
                        >
                        <v-icon v-else>mdi-menu-down</v-icon>
                      </span>
                    </div>
                  </div>
                </v-list-item-content>
              </template>

              <v-list-group
                v-for="(question, index) in item.ChapterQuestion"
                :key="index"
                sub-group
                class="blue lighten-5"
              >
                <template v-slot:activator>
                  <v-list-item-content
                    @click="showChapterQuestion(question.Question.key)"
                  >
                    <div class="resp-table-row">
                      <div class="py-3 table-body-cell text-left">
                        <span class="question-name">
                          {{ item.sort }} .{{ index + 1 }}.
                          {{
                            JSON.parse(question.Question.translatableName)[
                              $_getLocale()
                            ]
                          }}
                        </span>
                      </div>
                      <div
                        class="pa-3 table-body-cell"
                        v-if="question.Question.Record"
                      >
                        <span v-if="question.Question.Submission">
                          {{ question.Question.Submission.length }}</span
                        >
                        <span v-else>-</span>
                      </div>
                      <div class="pa-3 table-body-cell" v-else>0</div>
                      <div
                        class="pa-3 table-body-cell"
                        v-if="question.Question.Record"
                      >
                        <span
                          v-if="
                            (question.Question.Record.totalCorrect /
                              question.Question.Record.totalQuestion) *
                              100 <
                            60
                          "
                        >
                          0 ★</span
                        >
                        <span
                          v-else-if="
                            (question.Question.Record.totalCorrect /
                              question.Question.Record.totalQuestion) *
                              100 >=
                              60 &&
                            (question.Question.Record.totalCorrect /
                              question.Question.Record.totalQuestion) *
                              100 <
                              80
                          "
                        >
                          1 ★</span
                        >
                        <span
                          v-else-if="
                            (question.Question.Record.totalCorrect /
                              question.Question.Record.totalQuestion) *
                              100 >=
                              80 &&
                            (question.Question.Record.totalCorrect /
                              question.Question.Record.totalQuestion) *
                              100 <
                              100
                          "
                        >
                          2 ★</span
                        >
                        <span
                          v-else-if="
                            (question.Question.Record.totalCorrect /
                              question.Question.Record.totalQuestion) *
                              100 >=
                            100
                          "
                        >
                          3 ★</span
                        >
                      </div>
                      <div class="pa-3 table-body-cell" v-else>0 ★</div>
                      <!-- 
                      <div
                        class="pa-3 table-body-cell"
                        v-if="question.Question.Record"
                      >
                        {{ question.Question.Record.totalScore }}
                      </div>
                      <div class="pa-3 table-body-cell" v-else>0</div> -->
                      <div
                        class="pa-3 table-body-cell"
                        v-if="
                          question.Question.Record &&
                          question.Question.Record.totalCorrect
                        "
                      >
                        {{ question.Question.Record.totalCorrect }}/{{
                          question.Question.Record.totalQuestion
                        }}
                      </div>
                      <div class="pa-3 table-body-cell" v-else>0</div>
                      <div
                        class="pa-3 table-body-cell"
                        v-if="
                          question.Question.Record &&
                          question.Question.Record.totalCorrect
                        "
                      >
                        {{
                          Math.round(
                            ((question.Question.Record.totalCorrect /
                              question.Question.Record.totalQuestion) *
                              100 +
                              Number.EPSILON) *
                              100
                          ) / 100
                        }}%
                      </div>
                      <div class="pa-3 table-body-cell" v-else>0%</div>
                      <!-- <div
                        class="pa-3 table-body-cell"
                        v-if="question.Question.Record && question.Question.Record.countCorrect"
                      >
                        {{ question.Question.Record.time }}
                      </div>
                      <div class="pa-3 table-body-cell" v-else>0</div> -->
                      <div class="pa-3 table-body-cell">
                        <span
                          ><v-icon
                            v-if="
                              questionKeyShow.includes(question.Question.key)
                            "
                            >mdi-menu-up</v-icon
                          >
                          <v-icon v-else>mdi-menu-down</v-icon>
                        </span>
                      </div>
                    </div>
                  </v-list-item-content>
                </template>
                <div v-if="question.Question.Submission">
                  <div
                    v-for="(submissionItem, index) in question.Question
                      .Submission"
                    :key="index"
                  >
                    <v-list-item link class="teal lighten-4">
                      <div class="resp-table-row">
                        <div class="table-body-cell text-left">
                          <span class="submission-name">
                            {{
                              $moment(submissionItem.timestampCreated).format(
                                "DD/MM/YYYY hh:mm a"
                              )
                            }}
                          </span>
                        </div>
                        <div class="pa-3 table-body-cell">
                          {{ question.Question
                      .Submission.length - index }}
                        </div>
                        <div class="pa-3 table-body-cell">
                          <span v-if="submissionItem.accuracy * 100 < 60">
                            0 ★</span
                          >
                          <span
                            v-else-if="
                              submissionItem.accuracy * 100 >= 60 &&
                              submissionItem.accuracy * 100 < 80
                            "
                          >
                            1 ★</span
                          >
                          <span
                            v-else-if="
                              submissionItem.accuracy * 100 >= 80 &&
                              submissionItem.accuracy * 100 < 100
                            "
                          >
                            2 ★</span
                          >
                          <span
                            v-else-if="submissionItem.accuracy * 100 >= 100"
                          >
                            3 ★</span
                          >
                        </div>
                        <!-- 
                      <div class="pa-3 table-body-cell">
                        {{ submissionItem.score }}
                      </div> -->
                        <div class="pa-3 table-body-cell">
                          {{ submissionItem.countCorrect }}/{{
                            submissionItem.countQuestion
                          }}
                        </div>
                        <div class="pa-3 table-body-cell">
                          {{ submissionItem.accuracy * 100 }}%
                        </div>
                        <!-- <div class="pa-3 table-body-cell">
                        {{ submissionItem.time }}
                      </div> -->
                        <div class="pa-3 table-body-cell"></div>
                      </div>
                    </v-list-item>
                  </div>
                </div>
                <div v-else>
                  <v-list-item class="teal lighten-4">
                    {{ $t("view.PagePerformance.no_items") }}
                  </v-list-item>
                </div>
                <div
                  v-if="
                    question.Question.Submission &&
                    !question.Question.Submission.some(
                      (item) => item.questionKey === question.Question.key
                    )
                  "
                >
                  <v-list-item class="teal lighten-4">
                    {{ $t("view.PagePerformance.no_items") }}
                  </v-list-item>
                </div>
              </v-list-group>
            </v-list-group>
          </v-list>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [],
  data: () => ({
    chaptrs: [],
    records: [],
    recordsByChapter: [],
    recordsByQuestion: [],
    chapterWithRecord: [],
    questionWithRecord: [],
    submission: [],
    submissionByQuestion: [],
    chapterKey: null,
    questionKey: null,
    questionIndex: 0,
    chapterKeyShow: null,
    questionKeyShow: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    isShowQuestion: false,
    data: [],
  }),
  created() {
    //BOC:[api]
    this.api.url =
      this.$api.servers.log +
      "/log/v1/" +
      this.$_getLocale() +
      "/performance/student/" +
      this.$route.params.id;
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      if (resp.length != 0) {
        if (!resp.Performance && resp[0].ChapterQuestion) {
          this.chaptrs = resp;
          this.chapterWithRecord = this.$_.cloneDeep(this.chaptrs);
          this.filterRecord();
          this.filterQuestion();
          this.getSubmission();
        } else if (resp.Performance) {
          this.records = resp.Performance;
          this.getChaptrs();
        } else {
          this.submission = resp;
          this.filterSubmission();
        }
      } else {
        if (this.chapterWithRecord.length == 0) {
          this.getChaptrs();
        }
      }

      // const pluck = (arr, key) => arr.map((i) => i[key]);
      // this.records = pluck(this.data, "Record");
    };
    //EOC
  },
  beforeMount() {
    this.$api.fetch(this.api);
  },
  methods: {
    showChapter(key) {
      if (key == this.chapterKeyShow) {
        this.chapterKeyShow = null;
      } else {
        this.chapterKeyShow = key;
      }
    },
    showChapterQuestion(key) {
      if (this.questionKeyShow.includes(key)) {
        var index = this.questionKeyShow.indexOf(key);
        this.questionKeyShow.splice(index, 1);
      } else {
        this.questionKeyShow.push(key);
      }
    },
    getChaptrs() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/" +
        this.$_getLocale() +
        "/data/classroom/" +
        this.$route.params.parentId +
        "/chapter";
      this.$api.fetch(this.api);
    },
    getSubmission() {
      this.api.url =
        this.$api.servers.log +
        "/log/v1/" +
        this.$_getLocale() +
        "/submission/student/" +
        this.$route.params.id;
      this.$api.fetch(this.api);
    },
    showQuestion(key) {
      if (this.chapterKey) {
        this.chapterKey = null;
      } else {
        this.chapterKey = key;
        this.isShowQuestion = true;
      }
    },
    showSubmission(key, index) {
      if (this.questionKey) {
        this.questionKey = null;
        this.questionIndex = null;
      } else {
        this.questionKey = key;
        this.questionIndex = index;
      }
    },
    filterRecord() {
      // for (var n = 0, m = this.data.length; n < m; n++) {
      //   for (var i = 0, j = this.data[n].Record.length; i < j; i++) {
      //     this.records.push(this.data[n].Record[i]);
      //   }
      // }
      var result = [];
      this.records.reduce(function (res, value) {
        if (!res[value.chapterKey]) {
          res[value.chapterKey] = {
            chapterkey: value.chapterKey,
            totalCorrect: 0,
            totalQuestion: 0,
          };
          result.push(res[value.chapterKey]);
        }
        // res[value.chapterKey].totalScore += value.topScore;
        // res[value.chapterKey].totalStar += value.topStar;
        // res[value.chapterKey].totalAttempt += value.attempt;
        res[value.chapterKey].totalCorrect += value.totalCorrect;
        res[value.chapterKey].totalQuestion += value.totalQuestion;
        return res;
      }, {});
      this.recordsByChapter = result;
      for (var a = 0, b = this.recordsByChapter.length; a < b; a++) {
        this.joinRecordWithChapter(
          this.recordsByChapter[a].chapterKey,
          this.recordsByChapter[a]
        );
      }
    },
    joinRecordWithChapter(key, record) {
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        if (record.chapterkey == this.chapterWithRecord[p].key) {
          this.chapterWithRecord[p].Record = record;
        }
      }
    },
    filterQuestion() {
      //var result = [];
      // this.records.reduce(function (res, value) {
      //   if (!res[value.questionKey]) {
      //     res[value.questionKey] = {
      //       questionKey: value.questionKey,
      //       totalQuestion: 0,
      //       totalCorrect: 0,
      //     };
      //     result.push(res[value.questionKey]);
      //   }
      //   res[value.questionKey].totalQuestion += value.totalQuestion;
      //   res[value.questionKey].totalCorrect += value.totalCorrect;
      //   return res;
      // }, {});
      this.recordsByQuestion = this.records;
      for (var k = 0, l = this.recordsByQuestion.length; k < l; k++) {
        this.joinRecordWithQuestion(
          this.recordsByQuestion[k].questionKey,
          this.recordsByQuestion[k]
        );
      }
    },
    joinRecordWithQuestion(key, record) {
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        for (
          var x = 0, y = this.chapterWithRecord[p].ChapterQuestion.length;
          x < y;
          x++
        ) {
          if (
            key == this.chapterWithRecord[p].ChapterQuestion[x].Question.key
          ) {
            this.chapterWithRecord[p].ChapterQuestion[x].Question.Record =
              record;
          }
        }
      }
      this.orderQuestion();
    },
    filterSubmission() {
      this.submission.sort((a, b) =>
        a.timestampCreated > b.timestampCreated ? 1 : -1
      );
      var result = [];
      this.submission.reduce(function (res, value) {
        if (!res[value.questionKey]) {
          res[value.questionKey] = {
            questionKey: value.questionKey,
            totalQuestion: 0,
            totalCountCorrect: 0,
            totalTime: 0,
          };
          result.push(res[value.questionKey]);
        }
        res[value.questionKey].totalCountCorrect += value.countCorrect;
        res[value.questionKey].totalQuestion += value.countQuestion;
        res[value.questionKey].totalTime += value.time;
        return res;
      }, {});
      this.submissionByQuestion = result;
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        for (
          var x = 0, y = this.chapterWithRecord[p].ChapterQuestion.length;
          x < y;
          x++
        ) {
          this.joinSubmissionWithQuestion(
            this.chapterWithRecord[p].ChapterQuestion[x]
          );
        }
      }
    },
    joinSubmissionWithQuestion(question) {
      for (var p = 0, q = this.submissionByQuestion.length; p < q; p++) {
        if (question.Question.key == this.submissionByQuestion[p].questionKey) {
          question.Question.Record.countCorrect =
            this.submissionByQuestion[p].totalCountCorrect;
          question.Question.Record.countQuestion =
            this.submissionByQuestion[p].totalQuestion;
          question.Question.Record.time =
            this.submissionByQuestion[p].totalTime;
        }
      }
      var submission = [];
      for (var a = 0, b = this.submission.length; a < b; a++) {
        if (this.submission[a].questionKey == question.Question.key) {
          submission.push(this.submission[a]);
          submission.sort((a, b) =>
            a.timestampCreated < b.timestampCreated ? 1 : -1
          );
        }
      }
      question.Question.Submission = submission;

      this.addAttemptToChapter();
    },
    orderQuestion() {
      if (this.submission.length == 0) {
        this.addAttemptToChapter();
      }
      for (var p = 0, q = this.chapterWithRecord.length; p < q; p++) {
        for (
          var x = 0, y = this.chapterWithRecord[p].ChapterQuestion.length;
          x < y;
          x++
        ) {
          this.chapterWithRecord[p].ChapterQuestion.sort((a, b) =>
            a.Question.key > b.Question.key ? 1 : -1
          );
        }
      }
    },
    addAttemptToChapter() {
      for (var a = 0, b = this.chapterWithRecord.length; a < b; a++) {
        var attempt = 0;
        var star = 0;
        for (
          var n = 0, m = this.chapterWithRecord[a].ChapterQuestion.length;
          n < m;
          n++
        ) {
          if (
            this.chapterWithRecord[a].ChapterQuestion[n].Question.Submission
          ) {
            attempt =
              attempt +
              this.chapterWithRecord[a].ChapterQuestion[n].Question.Submission
                .length;
          }
          if (this.chapterWithRecord[a].ChapterQuestion[n].Question.Record) {
            star =
              star +
              this.countChapterStar(
                this.chapterWithRecord[a].ChapterQuestion[n].Question.Record
                  .totalCorrect,
                this.chapterWithRecord[a].ChapterQuestion[n].Question.Record
                  .totalQuestion
              );
          }
        }
        if (this.chapterWithRecord[a].Record) {
          this.chapterWithRecord[a].Record.attempt = attempt;
          this.chapterWithRecord[a].Record.star = star;
        }
      }
    },
    countChapterStar(countCorrect, countQuestion) {
      if ((countCorrect / countQuestion) * 100 < 60) {
        return 0;
      } else if (
        (countCorrect / countQuestion) * 100 >= 60 &&
        (countCorrect / countQuestion) * 100 < 80
      ) {
        return 1;
      } else if (
        (countCorrect / countQuestion) * 100 >= 80 &&
        (countCorrect / countQuestion) * 100 < 100
      ) {
        return 2;
      } else {
        return 3;
      }
    },
  },
};
</script>
<style>
#resp-table-header {
  display: table-header-group;
  background-color: gray;
  font-weight: bold;
  font-size: 25px;
}
.table-header-cell {
  display: table-cell;
  width: 140px !important;
  text-align: right !important;
  border-bottom: 1px solid black;
}
#resp-table-body {
  display: table-row-group;
}
.resp-table-row {
  display: table-row;
}
.table-body-cell {
  display: table-cell;
  width: 140px !important;
  text-align: right !important;
}

.question-name {
  display: block !important;
  width: 155px !important;
  word-wrap: break-word !important;
}
.submission-name {
  display: block !important;
  width: 145px !important;
  word-wrap: break-word !important;
}
.chapter-name {
  display: block !important;
  width: 170px;
  word-wrap: break-word !important;
}
.performance .v-list-item__icon {
  display: none;
}
</style>