<template>
  <div v-if="options.xaxis.categories && series[0]" class="ma-1">
    <VueApexCharts
      height="420px"
      type="heatmap"
      :options="options"
      :series="series"
    ></VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  components: {
    VueApexCharts,
  },
 props: [
    "submission",
  ],
  data: () => ({
   
    attempts: [],
    attemptsFilter: [],
    alldatesWithYear:[],
    names: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    options: {
      chart: {
        height: 330,
        type: "heatmap",
        toolbar: {
          show: false,
        },
        animations:{
          enabled:false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      title: {
        text: "Submissions Chart",
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
    },
    series: [],
  }),
  
  methods: {
    dates() {
      var xRange = 20;
      //
      var submission = this.$_.clone(this.submission)
      // //
     
      //
      var dayFirst, dayLast;
      // 
       var dayStart =  moment(submission[0].timestampCreated).subtract(xRange,'days').startOf('day');
      var dayEnd =  moment(submission[0].timestampCreated).startOf('day');
      dayLast = dayEnd
       dayFirst = dayStart
      //BOC: generate dates
      var datesWithYear = []
      for(var d=0;d<=xRange;d++) {
        var newDay = dayFirst.clone().add(d,"days")
        if(newDay > dayLast) break
      //  dates.push(newDay.format("DD/MM"))
        datesWithYear.push(newDay.format("DD/MM/YYYY"))
      }
      this.alldatesWithYear = datesWithYear
      //EOC
      this.names.reverse();
      for (var j = 0, k = this.alldatesWithYear.length; j < k; j++) {
        for (var n = 0, m = submission.length; n < m; n++) {
          if (
            this.alldatesWithYear[j] ==
            moment(submission[n].timestampCreated).format("DD/MM/YYYY")
          ) {
            this.attempts.push({
              date: this.alldatesWithYear[j],
              fullDate: submission[n].timestampCreated,
              time: moment(submission[n].timestampCreated).format("HH:00"),
              attempts: 1,
            });
          }
        }
      }
      var temp_attempts = this.attempts;
      var temp_names = this.names;
      for (var i = 0, l = this.names.length; i < l; i++) {
        var data = [];
        this.alldatesWithYear.forEach(function (date) {
          var attempt = 0;
          temp_attempts.forEach(function (item) {
            if (item.date == date && item.time == temp_names[i]) {
              attempt += item.attempts;
            }
          });
          data.push(attempt);
        });

        this.series.push({
          name: temp_names[i],
          data: data,
        });
        this.options.xaxis.categories = this.$_.cloneDeep(this.alldatesWithYear);
      }
    },
  },
  mounted() {
     this.dates()
  },
};
</script>