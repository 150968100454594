<template>
  <div>
    <v-tabs centered show-arrows>
      <v-tab> {{$t("view.PagePerformance.performance_table")}} </v-tab>
      <v-tab>{{$t("view.PagePerformance.performance_chart")}}</v-tab>

      <v-tab-item>
        <div>
          <PagePerformance
            role="Moderator"
            :model="model"
            :chaptrs="data"
          ></PagePerformance>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div>
          <PageChart></PageChart>
        </div>
      </v-tab-item>
    </v-tabs>
    <!-- BOC:[table] -->

    <!-- EOC -->
  </div>
</template>

<script>
//BOC:[model]
import model from "@/models/items/log";
//EOC
//BOC:[table]
import PageChart from "@/components/Bread/Student/Performance/PageChart";
import PagePerformance from "@/components/Bread/Student/Performance/PagePerformance";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    PagePerformance,
    PageChart,
    //EOC
  },
  computed: mapState({
    //
  }),
  props: ["role", "parent"],
  data: () => ({
    //BOC:[model]
    model: model,
    //EOC
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
  }),
  created() {
    //BOC:[api]
    this.api.url =
      this.$api.servers.backend + "/v1/" + this.$_getLocale() + "/data/chapter";
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };
    //EOC
  },
  mounted() {
    // this.$api.fetch(this.api);
  },
  methods: {
    //
  },
};
</script>