<template>
 <v-container>
  <v-card :loading="api.isLoading"  class="mx-auto">
    <v-card-text>
      <div v-if="submission[0]">
        <ChartAttempt :submission="submission" />
          <div class="text-caption text-right pt-2">
       Recent records are shown here.
    </div>
      </div>
        <div v-else class="d-flex flex-column justify-content-center my-10 align-center">
           {{ $t("view.PagePerformance.no_items") }}
        </div>
     
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
import ChartAttempt from "@/components/Bread/Student/Performance/ChartAttempt2";
export default {
  components: {
    ChartAttempt,
  },

  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    submission: [],
    start: null,
    end: null,
  }),
  created() {
    //BOC:[api]
    this.api.url =
      this.$api.servers.log +
      "/log/v1/" +
      this.$_getLocale() +
      "/submission/student/" +
      this.$route.params.id;
    this.api.method = "get";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.submission = resp;
      this.submission.sort((a, b) =>
        a.timestampCreated < b.timestampCreated ? 1 : -1
      );
    };
  },
  beforeMount() {
    this.$api.fetch(this.api);
  },
};
</script>